import React from "react";
import Video from "../../components/Video/Video";
import CarruselB from "../../components/Carrusel/CarruselB";
import { Link } from "react-router-dom";


const RolSensorial = () => {
  return (
    <div className="mx-5 ">
      <h1 className="text-4xl text-gray-300 bg-[#194346] w-screen	p-5 flex justify-center">
        Rol sensorial, una experiencia de juego inclusiva
      </h1>
      <script src="ruta-del-archivo-bootstrap.js"/>
      <script>
        $('.carousel').carousel();
      </script>

      <CarruselB/>
    
      <div className="text-[#03282c] md:p-4 md:p-6 bg-[#a6c0c2]">
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          Los juegos de rol son una actividad lúdica en la que los jugadores asumen el rol de personajes 
          ficticios en un mundo imaginario. En el rol sensorial, los jugadores juegan con los ojos vendados, 
          lo que les motiva a desarrollar sus habilidades de comunicación no visual, y les potencia la imaginación 
          por medio de estimulaciones sensoriales.
        </p>     
      </div>

      <h2 className="text-2xl text-gray-300 bg-[#194346] w-screen	p-3 flex justify-center">
        ¿Qué es el rol sensorial?
      </h2>

      <div className="text-[#03282c] md:p-4 md:p-6 bg-[#a6c0c2]">
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          El rol sensorial es una experiencia de juego inversiva donde anulamos el sentido de la vista, 
          y potenciamos los otros sentidos (el tacto, la audición). En este tipo de juego, los jugadores 
          deben aprender a comunicarse y a interactuar entre sí sin usar la vista.
        </p>     
      </div>
      
     
      <Video id="i8WnKu5bfiE" /> 

      <h2 className="text-2xl text-gray-300 bg-[#194346] w-screen	p-3 flex justify-center">
        ¿Por qué es una experiencia inclusiva?
      </h2>
      
      <div className="text-[#03282c] md:p-4 md:p-6 bg-[#a6c0c2]">
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            Permite que personas con y sin discapacidad visual puedan jugar sin ninguna adtapación ni conocimiento 
            previo. Es un sistema diseñado y desarrollado por Miguel Barraza.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            En los videos se pueden ver las experiencias de jugadores que vivieron el rol sensorial. 
            Estos testimonios nos permiten conocer cómo fue la experiencia para ellos, tanto desde el 
            punto de vista de las personas con discapacidad visual como de las personas sin discapacidad.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            En general, los jugadores coinciden en que el rol sensorial fue una experiencia muy enriquecedora. 
            Los jugadores con discapacidad visual destacan que les permitió jugar a juegos de rol por primera 
            vez, y conocer otros sistemas de juegos, como también vivir la experiencia de un juego donde se 
            sienten incluídos y en igualdad de oportunidades.
            Mientras que los jugadores sin discapacidad visual destacan que les ayudó a desarrollar sus 
            habilidades de comunicación no visual, pudieron potenciar mas su imaginación, vivir una experiencia 
            de juego diferente, y jugar con los sentidos.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            A continuación, se presentan algunos comentarios específicos de los jugadores que vivieron esta experiencia.
        </p>
        <ol className=" pb-1 max-w-lg mx-auto pl-5 ">
          <li> * "El rol sensorial me permitió experimentar el mundo de una manera completamente nueva. 
              Aprendí a confiar en mis otros sentidos y a comunicarme con los demás de una manera más 
              profunda".</li>
          <li> *  "El rol sensorial fue un desafío, pero también fue muy gratificante. Aprendí a valorar 
              la comunicación no visual y a entender mejor las experiencias de las personas con discapacidad visual"</li>
        </ol>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            Estos testimonios demuestran que el rol sensorial es una experiencia inclusiva que puede enriquecer 
            la vida de todos los jugadores, independientemente de su discapacidad.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            Además de los videos, también se presenta un carrusel de fotos de personas jugando al rol sensorial. 
            Estas fotos nos permiten ver cómo se desarrolla el juego y cómo interactúan los jugadores.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
            En definitiva, el rol sensorial es una experiencia única que puede ayudar a promover la inclusión 
            y la diversidad en el mundo de los juegos.
        </p>
      </div>

      <h2 className="text-2xl text-gray-300 bg-[#194346] w-screen	p-3 flex justify-center">
          Organizadores de eventos de juegos
      </h2>

      <div className="text-[#03282c] md:p-4 md:p-6 bg-[#a6c0c2]">
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          ¿Están buscando una experiencia única e inclusiva para su evento?
        </p>  
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          Esta experiencia es inclusiva porque permite que personas con o sin conocimiento en juegos, 
          y personas con discapacidad visual puedan jugar bajo las mismas condiciones. Además, es una 
          experiencia que puede ayudar a las personas sin discapacidad a desarrollar sus habilidades de 
          comunicación no visual.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          Si están interesados en organizar un evento de juegos inclusivos, contáctame para conocer 
          más sobre el rol sensorial.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          Estoy disponible para eventos de todos los tamaños, desde pequeños eventos locales hasta 
          grandes eventos internacionales.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          <p>Para obtener más información, pueden ponerse en 
              <Link to="/Contacto" class="text-red-600"> contacto </Link> conmigo.
          </p>
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          También puede ser contratado para team building en empresas.
        </p>
        <p className="pb-2 max-w-lg mx-auto pl-10 pr-10 pt-2">
          ¡El rol sensorial es una experiencia única que puede enriquecer la vida de todos los jugadores, 
          independientemente de su discapacidad!
        </p>
      </div>
    </div>
  );
};

export default RolSensorial;
