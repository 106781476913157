import React from 'react';


const CarruselB = () => {
  const imageStyle = {
    height: '20rem', // El alto que desees para todas las imágenes
    objectFit: 'cover', // Para mantener la relación de aspecto y cubrir el área especificada
  };

  return(
    
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
      </ol>

      <div class="carousel-inner">
        <div class="carousel-item active">
          <img 
            style={imageStyle}
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_1.JPG" 
            class="d-block w-50% mx-auto" 
            alt="Tres jugadores con antifaces sentados en un sillón. La chica de la izquierda, está prestando atención."
          />
        </div>
        <div class="carousel-item">
          <img
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_2.JPG" 
            alt="Dos jugadores sentados en un sillón. Uno de ellos entrega sonriente el control remoto a Miguel. Sobre una mesa hay un parlante y la identificación del juego."
          />
        </div>
        <div class="carousel-item">
          <img 
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_3-1.JPG"
            alt="dos jugadores sonrientes sentados, uno de ellos está pasando algo que tiene en su mano." 
          />
        </div>
        <div class="carousel-item">
          <img 
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_3-2.JPG"
            alt="tres jugadores sentados en un sillón. Uno de ellos entrega sonriente el control remoto a Miguel. Sobre una mesa está la identificación del juego." 
          />
        </div>
        <div class="carousel-item">
          <img 
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_3-3.JPG"
            alt="Se visualiza tres jugadores, la del medio tiene en su mano el control remoto apretando un botón" 
          />
        </div>
        <div class="carousel-item">
          <img 
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_%204-1.JPG"
            alt="Miguel de perfil sonriendo con las manos apoyadas sobre la mesa, en ella se ve el parlante y el tríptico de la actividad. sentados están dos participantes con antifaces, escuchando la explicación"
          />
        </div>
        <div class="carousel-item">
          <img 
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Rosario_23_4-2.jpg"
            alt="Dos jugadores riendo, EL de la derecha tiene su puño apretado como símbolo de victoria."
          />
        </div>
        <div class="carousel-item">
          <img 
            style={imageStyle}
            class="d-block w-50% mx-auto"
            src="https://miguelbarrazaar.github.io/webcontents/images/Mexico_23-1.jpg"
            alt="Al rededor de una mesa están los participantes del encuentro de rol sensorial en México. Miguel sostiene el escudo de 'La taberna Rolera'"
          />
        </div>
        
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  );  
};

export default CarruselB;