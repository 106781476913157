import * as React from "react";
import { Typography } from "@mui/material";
import {
  FaLinkedinIn,
  FaInstagram,
  FaGithubSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <hr />
      <div className="max-w-[1240px]  py-16 text-gray-300  ">
        <div className=" py-2 flex justify-center">
          <p className="py-4 ">Redes sociales:</p>
        </div>
        <div className="mx-auto sm: flex justify-between m-0 my-5 p-0     md:max-w-[600px] px-20">
          <Typography
            variant="caption"
            component="a"
            href="https://www.linkedin.com/in/MiguelBarrazaAr/"
            target="_blank"
            className="grid justify-items-center"
          >
            <FaLinkedinIn size={30} aria-labelledby="Linkedin link" />
            Linkedin
          </Typography>
          <Typography
            variant="caption"
            component="a"
            href="https://www.instagram.com/MiguelBarrazaaccesibilidad/"
            target="_blank"
            className="grid justify-items-center"
          >
            <FaInstagram size={30} aria-labelledby="Instagram link" />
            instagram
          </Typography>
          <Typography
            variant="caption"
            component="a"
            href="https://github.com/MiguelBarrazaAr"
            target="_blank"
            className="grid justify-items-center"
          >
            <FaGithubSquare size={30} aria-labelledby="Github link" />
            github
          </Typography>
        </div>
        <p className="pl-6 mx-auto flex-column flex justify-center text-xs">
          <strong>
© 2024  Miguel Barraza ® marca registrada
</strong>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
