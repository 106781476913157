import React from 'react';

const Video = (props) => {
    const videoUrl = `https://www.youtube.com/embed/${props.id}?si=QJro2oB8NNDZ851L`;
    const videoStyle= {
        width: 360,
        height: 240,
        objectFit: 'cover',
        margin:'1%',
    }

    return (
        <div className='row justify-content-center'>        
            <iframe
                src={videoUrl}
                title="YouTube video player"
                style={videoStyle}
                frameBorder="0"
            />
        </div>
    );
};

export default Video;
