import React from "react";
import { Typography } from "@mui/material";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="content-start">
      <h2 className="text-4xl text-gray-300 bg-[#194346] w-screen	p-5 flex justify-center">
          Contacto
      </h2>
        <Typography variant="p" className="text-[#03282c] p-6 bg-[#a6c0c2] w-screen flex justify-center ">
        <strong>Envíame un mensaje, Te responderé tan pronto como pueda </strong>
        </Typography>
      
        <div className="text-lg text-[#03282c] flex flex-row p-10 bg-[#a6c0c2] w-screen h-64 flex justify-center"> 
        <Typography
          variant="caption"
          component="a"
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5491164240985&text=hola,%20soy%20%20y%20te%20contacto%20desde%20el%20portal%20miguelbarraza.com.ar%20para"
          className="flex flex-col items-center pr-10"
        >
        <FaWhatsapp className="text-4xl text-[#03282c]" aria-label="WhatsApp link"/> 
          WhatsApp
        </Typography>
        <Typography
          variant="caption"
          component="a"
          target="_blank"
          href="https://t.me/MiguelBarrazaAr"
          className="flex flex-col items-center pl-10"
        >
          <FaTelegramPlane className="text-4xl text-[#03282c]" aria-label="Telegram link"/> 
          Telegram
        </Typography>
        </div >
    </div>
  );
};

export default Contact;
