import React, { useState } from "react";
import logoBlancoConNombre from "../../assets/logos/logo-blanco-con-nombre.svg"
import { FaGripLines } from "react-icons/fa";


const Navbar = () => {
  let Links = [
    { name: "Inicio", link: "/" },
    { name: "Quien soy", link: "/info/quien-soy" },
    { name: "Rol Sensorial", link: "/rol-sensorial"},
    { name: "Contacto", link: "/contacto" },
    
  ];
  let [open, setOpen] = useState(false);
  return (
    <div className='shadow-md w-full fixed top-0 left-0 bg-[#001112]'  style={{ zIndex: '999' }}>
      <div className='md:flex items-center justify-between py-1 md:px-10 px-7'>
      
      <img
        src={logoBlancoConNombre}
        className="App-logo z-10	m-4 hover:scale-110 duration-100"
        alt="logo que contiene un ojo con el cursor del mouse en el iris. Abajo el nombre de Miguel Barraza"
        width="50"  
      />

        <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
          <FaGripLines name={open ? 'close' : 'menu'} color="white"></FaGripLines>
        </div>

        <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-[#001112] md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20 ' : 'top-[-490px]'}`}>
          {
            Links.map((link) => (
              <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7'>
                <a href={link.link} className='text-gray-100 hover:text-[#12e5f9] duration-500'>{link.name}</a>
              </li>
            ))
          }


        </ul>
      </div>
    </div>
  )
}
export default Navbar
