import React from "react";
import { Typography } from "@mui/material";

const QuienSoy = () => {
  return (
<div className="mx-5 ">
      <h1 className="text-4xl text-gray-300 bg-[#194346] w-screen	p-5 flex justify-center">
          Quién Soy
      </h1>
      
      <Typography variant="body1" className="text-[#03282c] p-6 bg-[#a6c0c2] w-screen justify-center">
      <img src="https://miguelbarrazaar.github.io/webcontents/images/foto_perfil.jpg"
        alt="foto de miguel"
        width="30%"
        height="30%"
        className="rounded-xl mx-auto mb-5"       
      />
        <p className="pb-3 max-w-lg mx-auto">
          Soy Miguel Barraza, consultor en accesibilidad digital, activista y
          speaker internacional.
        </p>     
        <p className="pb-3 max-w-lg mx-auto">
          He tenido el privilegio de compartir mi conocimiento y experiencia con
          audiencias de todo el mundo.
        </p>
        <p className="pb-3 max-w-lg mx-auto">
          Actualmente, me desempeño como software backend developer y accessibility consultant en
          MercadoLibre, una de las principales empresas de comercio electrónico
          en América Latina.
        </p>
        <p className="pb-3 max-w-lg mx-auto">
          Mi experiencia en el desarrollo de software me brinda una perspectiva
          única y valiosa para abordar los desafíos de accesibilidad en el
          ámbito digital.
        </p>
        <p className="pb-3 max-w-lg mx-auto">
          Además de mi trabajo en el desarrollo de software, también soy
          reconocido como:
        </p>
        <ol className=" pb-5 max-w-lg mx-auto pl-5">
          <li>- Coach motivacional</li>
          <li>- Facilitador en accesibilidad, inteligencia artificial y programación de software</li>
          <li>- Capacitador especializado en buenas prácticas de accesibilidad en el desarrollo de software</li>
          <li>- Tester de accesibilidad</li>
          <li>- Influencer</li>
        </ol>
        <p className="pb-3 max-w-lg mx-auto">
          Mi habilidad para comunicar de manera efectiva y mi pasión por ayudar
          a otros, me convierten en un recurso invaluable para aquellos que
          buscan mejorar sus habilidades y conocimientos en este campo en
          constante evolución.
        </p>

        <p className="pb-3 max-w-lg mx-auto">
          Me enorgullece poder inspirar, educar y guiar a otros, dejando un
          impacto positivo tanto en el ámbito tecnológico como en la comunidad
          en general. Estoy comprometido en seguir marcando la diferencia y
          contribuir al avance de la accesibilidad digital, fomentando un
          entorno inclusivo para todos, y luchar por la igualdad de
          oportunidades.
        </p>
      </Typography>
      <h2 className="text-2xl text-gray-300 bg-[#194346] w-screen	p-3 flex justify-center">
        Me interesa:
      </h2>
      <Typography variant="p" className="text-[#03282c] p-6 bg-[#a6c0c2] w-screen flex justify  " >
        <ul className="pb-3 max-w-lg mx-auto">
          <li> • Poder participar en proyectos de accesibilidad: productos o servicios digitales, colaborando con equipos.</li>
          <li> • Dar capacitaciones: en congresos y eventos.</li>
          <li> • Investigaciones científicas o técnicas: en robótica, inteligencia artificial, sonido espacial.</li>
          <li> • Participación en activismo social: con ONG o fundaciones que fomenten el aprendizaje, la integración social. </li>
          <li> • Actividades lúdicas inclusivas: desarrollo de juegos, eventos de juegos, o experiencias de rol. </li>
          <li> • Aprendizaje técnico: capacitar, enseñar, fomentar la tecnología de forma amigable. </li>
          <li> • Difusión tech: difundir nuevas tecnologías, inteligencia artificial,programación. </li>
          <li> • Brindar mentorías: para desarrollo profesional en tecnología o mejora de la accesibilidad en tu proyecto.
          </li>
          <li>  Si estás interesado/a en alguna de estas áreas y crees que podemos 
            hacer cosas juntos, contáctame. </li>
         </ul>
      </Typography>   
      <h2 className="text-2xl text-gray-300 bg-[#194346] w-screen	p-3 flex justify-center">
        Mis valores:
      </h2>
      <Typography variant="p" className="text-[#03282c] p-6 bg-[#a6c0c2] w-screen flex justify ">
        <ul className="pb-3 max-w-lg mx-auto">
          <li>• Creo en la transformación social. Quiero lograr un cambio positivo
            en el mundo, que promueva la igualdad, la inclusión y la accesibilidad para todas las personas.</li>
          <li> • Soy creativo. Busco nuevas formas de abordar los problemas sociales y promover la inclusión.</li>
          <li> • Creo en la educación. Creo que la educación es una herramienta esencial para el cambio social.
          </li>
          <li> • Creo en la inclusión. Creo que todas las personas, sin importar sus circunstancias, merecen tener las mismas oportunidades y acceso a los recursos. </li>
          <li>• Trabajo en colaboración.</li>
          <li> • Estoy abierto a trabajar con otros para lograr igualdad de oportunidades.</li>
        </ul>
      </Typography>
      </div>
  );
};

export default QuienSoy;
