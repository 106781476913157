import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const UserContext = React.createContext({ state: '', setState: () => {} });
export const UserProvider = ({ children }) => {
  const [state, setState] = useState(0);
  const value = useMemo(
    () => ({
      state,
      setState
    }),
    [state]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
UserProvider.propTypes = { children: PropTypes.node.isRequired };
