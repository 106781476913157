import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios'; 
import Typography from '@mui/material/Typography'

const HomeNews = () => {
  const [state, setState] = useState({
    load: false,
    posts: [],
    error: ''
  });

  useEffect(() => {
    axios.get("http://localhost:8000/api/posts/")
    .then(res => setState({
      load: true,
      posts: res.data,
      error: ''
    }))
    .catch(error => setState({
      load: true,
      posts: [],
      error: error
    }));
  }, []);
  
  return (
    <Fragment>  
      <main className=" w-full mx-auto  h-screen bg-gray-300 p-10">
        <Typography variant="h2" className='text-[#03282c] '>
        Novedades
        </Typography>
      </main>
    </Fragment>
  );
};

export default HomeNews;
