import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import { UserProvider } from "./contexts/UserProvider";

// views
import Layout from "./layout/Layout";
import Error404 from "./views/Error/Error404";
import Home from "./views/Home/Home";
import HomeNews from "./views/Home/HomeNews";
import Post from "./views/Post/Post";
import Contact from "./views/Contact/Contact";
import QuienSoy from "./views/QuienSoy/quien-soy";
import RolSensorial from "./views/RolSensorial/rol-sensorial";
import ChatbotsC1 from "./views/ChatbotsC1";

const Router = () => (
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/news" element={<HomeNews />} />
          <Route exact path="/info/quien-soy" element={<QuienSoy />} />
          <Route exact path="/contacto" element={<Contact />} />
          <Route path="/posts/:postKey" element={<Post />} />
          <Route exact path="/rol-sensorial" element={<RolSensorial />} />
          <Route exact path="/info/chatbots-ia-c1-2024" element={<ChatbotsC1 />} />
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </UserProvider>
);

export default Router;
