import { Outlet } from 'react-router-dom';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Layout= () => (
    <>
      <Header /> 
      <main className=" flex flex-col space-y-4 items-center max-w-[1240px] mx-auto mt-16 p-10">
        <Outlet />
      </main>
      <Footer />
    </>
);

export default Layout;