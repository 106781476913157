import React from "react";
import { Typography } from "@mui/material";

const ChatbotsC1 = () => {
  return (
    <div className="mx-5 ">
      <h1 className="text-4xl text-gray-300 bg-[#194346] w-screen	p-5 flex justify-center">
        Chatbots
      </h1>

      <Typography
        variant="body1"
        className="text-[#03282c] p-6 bg-[#a6c0c2] w-screen justify-center"
        style={{ textAlign: 'justify' }}
      >
        <p className="pb-3 max-w-lg mx-auto">
          Estos chatbots han sido creados en un entorno educativo, en el curso 
          de Agora México (Inteligencia Artificial emprendedora), con fines de
          experimentación y aprendizaje.
        </p>

        <p className="pb-3 max-w-lg mx-auto">
          Es importante tener en cuenta que:
        </p>

        <ul className="pb-3 max-w-lg mx-auto pl-5">
          <li>- No se les brinda mantenimiento constante.</li>
          <li>
            - Sus respuestas no deben tomarse como información fidedigna o
            completa.
          </li>
          <li>
            - Su propósito es únicamente servir como prueba de concepto y
            herramienta educativa.
          </li>
        </ul>

        <p className="pb-3 max-w-lg mx-auto">
          A pesar de lo anterior, te invitamos a probarlos y explorar sus
          capacidades. Recuerda siempre ser crítico con la información que
          recibas y utilizar tu propio juicio para evaluar su veracidad.
        </p>

        <p className="pb-3 max-w-lg mx-auto">
          ¡Diviértete y aprende en este entorno experimental! ¡Gracias por tu
          comprensión!
        </p>
      </Typography>

      <p className="text-2xl text-gray-300 bg-[#194346] w-screen	p-3 flex justify-center">
        Proyectos
      </p>

      <Typography
        variant="p"
        className="text-[#03282c] p-6 bg-[#a6c0c2] w-screen flex justify"
        style={{ textAlign: 'justify' }}
      >
        <section name="chatbots" className="pb-3 max-w-lg mx-auto">
          
          <h2 className="pb-3">
            <Typography 
              variant="caption"
              component="a"           
              href="https://poe.com/Visionsinfronteras"
              target="_blank"
              className="grid justify-items-center"
              style={{ fontFamily:"verdana", fontSize:"0.95rem" }}
            >
              <strong>Visión sin fronteras (grupo 1)</strong>
              
            </Typography>
          </h2>
          <p className="pb-3">
            Soluciona el problema de la calidad de vida, dando información
            adecuada para satisfacer necesidades personales y en relación con
            la sociedad. Ayuda con la enseñanza del braille, siendo favorable
            también para que familias u otros agentes educativos lo aprendan y
            puedan interactuar con las personas con discapacidad.
          </p>
          <h3 className="pb-1">
            <u>Integrantes:</u>
          </h3> 
          <p className="pl-5">
            <ul>
              <li><strong>*</strong> Brenda Leal Hernandez</li>
              <li><strong>*</strong> Francisco José Gonzales Mendoza</li>
              <li><strong>*</strong> Juan Gabriel Soto</li>
          </ul>
          </p>
          
                 
          <h2 className="pb-3" style={{ marginTop: "30px" }}>
            <Typography
              variant="caption"
              component="a"
              href="https://poe.com/Salsadurisimabot"
              target="_blank"
              className="grid justify-items-center"
              style={{ fontFamily:"verdana", fontSize:"0.95rem" }}
            >
            <strong>Salsa Bot (grupo 2)</strong>
            </Typography>
          </h2>

          <p className="pb-3">
            Es un Asistente de ayuda para encontrar e identificar discografía
            y todo el entorno ligado a la música latina, valiéndose
            de información recopilada de las fuentes de producción
            discográfica. Salsa Bot podría suministrar la información o data
            pertinente a Toda aquella persona relacionada o que tiene interés
            en la música, fundamentalmente latina (salsa); pueden ser
            profesionales ligados a la producción musical, melómanos,
            coleccionistas o simples aficionados a la música.
          </p>
          <h3 className="pb-1">
            <u>Integrantes:</u>
          </h3>
          <ul className="pl-5">
            <li><strong>*</strong>Dante Raúl Pinto Ita</li>
            <li><strong>*</strong>Haymer Rendon Prado</li>
            <li><strong>*</strong>Hilda Laura Vázquez Villanueva</li>
            <li><strong>*</strong>Silvia Lorena Buenaño Carrillo</li>
          </ul>
          
          <h2 className="pb-3" style={{ marginTop: "30px" }}>
            <Typography
              variant="caption"
              component="a"
              href="https://poe.com/Activebot"
              target="_blank"
              className="grid justify-items-center"
              style={{ fontFamily:"verdana", fontSize:"0.95rem" }}
            >
              <strong>Active (grupo 3)</strong>
            </Typography>
          </h2>

          <p className="pb-3">
            “ACTIVE” es un asistente , para personas ciegas y de baja visión,
            que brinda la información de como se debe realizar de manera
            correcta ejercicios físicos o rutinas, por medio de una
            descripción para orientar al usuario según sus necesidades
            particulares.
          </p>
          <p className="pb-1">
            <u>Uso:</u>
          </p>
          <p className="pb-3">
            <ul className="pl-3">
            “ACTIVE”, permite realizar preguntas en la orientación de las
            mejores formas y más adecuadas maneras de realizar ejercicios
            físicos para mantener un buen estado de salud, por ejemplo,
            puede preguntársele, para una mujer de sesenta años qué
            ejercicios son recomendables para la salud del corazón?.
            </ul>
          </p>
          <p className="pb-1">
              <u>Utilidad:</u>
          </p>
          <p className="pb-3">
            <ul className="pl-3">
              “ACTIVE”, permite a las personas ciegas y de baja visión , obtener
              recomendaciones para realizar ejercicio o rutinas de
              acondicionamiento físico, según sus necesidades como estilo de
              vida, edad ysexo, agregando valor con sugerencias de hábitos
              saludables como alimentación o correcta hidratación, de manera
              verbal y especifica para obtener una orientación sin limites
              visuales como los tutoriales convencionales.
            </ul>
          </p>
          <h3 className="pb-1">
            <u>Integrantes:</u>
          </h3>
          <ul className="pl-5">
            <li><strong>*</strong>Gonzalo Alberto Barbosa Olarte</li>
            <li><strong>*</strong>Laura Elizabeth Lemura</li>
            <li><strong>*</strong>Lucía Velázquez</li>
            <li><strong>*</strong>María Teresa Galván Soria</li>
          </ul>
        




          
          <h2 className="pb-3" style={{ marginTop: "30px" }}>
            <Typography
              variant="caption"
              component="a"
              href="https://poe.com/Niamniam2024"
              target="_blank"
              className="grid justify-items-center"
              style={{ fontFamily:"verdana", fontSize:"0.95rem" }}
            >
              <strong>Ñamiñami (grupo 4)</strong>
            </Typography>
          </h2>

          <p className="pb-3">
            Proporciona recetas saludables, bajas en grasa, bajas en calorías,
            naturales, bajas en azúcar y demás criterios médicos recomendados.
          </p>
          <p className="pb-3">
            Cómo lo resuelve: Con fundamento en criterio proporcionado por el
            usuario, facilita recetas con sus ingredientes e instrucciones
            para prepararlas.
          </p>
          <p className="pb-3">
            Las recetas, deberán contener ingredientes saludables y el usuario
            puede proporcionar la lista de materiales con los que cuente.
          </p>
          <h3 className="pb-1">
            <u>Integrantes:</u>
          </h3>
          <ul className="pl-5">
            <li><strong>*</strong>Carmen Salazar Lagunes</li>
            <li><strong>*</strong>Gabriela López Cruz</li>
            <li><strong>*</strong>Laura Camila Rodríguez Carvajal</li>
            <li><strong>*</strong>Paula Slemenson</li>
          </ul>
          
          <article>
            <h2 className="pb-3" style={{ marginTop: "30px" }}>
              <Typography
                variant="caption"
                component="a"
                href="https://poe.com/StyleSenseAI"
                target="_blank"
                className="grid justify-items-center"
                style={{ fontFamily:"verdana", fontSize:"0.95rem" }}
              >
                <strong>StyleSense AI (grupo 5)</strong>
              </Typography>
            </h2>

            <p className="pb-3">
              Chatbot que te ayuda, persona con discapacidad visual, a planear
              tus outfit's para cada ocasión.
            </p>
            <p className="pb-3">
              Las personas con discapacidad visual a menudo presentan
              dificultades para crear outfit's de acuerdo a su día a día.  Con
              el chatbot pretendemos brindar orientación en este sentido de
              acuerdo a sus prendas disponibles.
            </p>
            <h3 className="pb-1">
              <u>Integrantes:</u>
            </h3>
            <ul className="pl-5">
              <li><strong>*</strong>Ángel De Jesús Alcántar Garza</li>
              <li><strong>*</strong>Hober Humberto Cuentas Mamani</li>
              <li><strong>*</strong>Monserrat León Flores</li>
            </ul>
          </article>
        </section>
      </Typography>
    </div>
  );
};

export default ChatbotsC1;
