import React from "react";
import Navbar from "./Navbar"

const Header = (props) => {
  if(props.title) {
    document.title = props.title;
  } else {
    document.title = 'Miguel Barraza';
  }
  return (
    <header >
      <Navbar/>
    </header>
  );
};

export default Header;
