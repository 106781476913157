import React from "react";
import Typography from "@mui/material/Typography";


const Home = () => {
  return (
    <div className="bg-[#001112] flex flex-col lg:flex-row-reverse items-center">

      <div className="h-[200px] w-[200px] rounded-full mx-9 bg-center bg-no-repeat bg-cover" style={{
        backgroundImage: `url('https://miguelbarrazaar.github.io/webcontents/images/Miguel-con-personas-trabajando-de-fondo.jpg')`,
        backgroundPosition: 'top right'
      }}>
        <p className="sr-only">Foto de Miguel Barraza en oficina con personas trabajando de fondo.</p>
      </div>

      <div>
        <h1 className=" text-6xl text-[#12e5f9] tracking-wide max-w-[800px] py-10 " >
          <strong >
            Miguel Barraza
          </strong>
        </h1>
        <h2 className=" text-3xl text-white tracking-wide pb-10 ">
          Speaker and accessibility consultant
        </h2>
        <img src="https://miguelbarrazaar.github.io/webcontents/images/bandera-argentina.jpg"
          alt="bandera argentina"
          width="50px"
          height="25px"
          className="mb-5"
        />
        <p className="text-white text-lg">
          <p>Soy consultor de accesibilidad web y desarrollador de software.</p>
          <p>En este sitio recompilo información y material que utilizo en mis charlas y mentorías.</p>
          <p>Puedes contactarme para invitaciones a eventos o solicitar una asesoría.</p>
        </p>
      </div>

    </div>
  );
};

export default Home;
