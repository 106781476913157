import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios'; 
import Typography from '@mui/material/Typography'
import Error404 from "../Error/Error404";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Post = (props) => {
  const [state, setState] = useState({
    load: false,
    post: '',
    error: false,
    errorData: null
  });
  
  const { postKey } = useParams();
  let url="http://data.miguelbarraza.com.ar/info/"+postKey;
  useEffect(() => {
    axios.get(url)
    .then(res => {
      setState({
      load: true,
      post: res.data,
      error: false,
      errorData: null
    })})
    .catch(error => {
      setState({
      load: true,
      post: [],
      error: true,
      errorData: error
    })});
  }, [url]);
  
  // validar si hay que poner pantalla de carga. 
  return (
    <>
    {state.error ? 
      <Error404 />
    : 
      <>
        <Typography variant="h1" className='text-[#03282c] '>
        {state.post.title}
        </Typography>
        <Typography variant="p" className='text-[#03282c] '>
        <ReactMarkdown children={state.post.text} remarkPlugins={[remarkGfm]} />
        </Typography>
    </>
    }
    </>
  );
};

export default Post;
