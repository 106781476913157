import React from 'react';
import Typography from '@mui/material/Typography'

// title="contenido no encontrado"
const Error404  = () => {
  return (
    <>
        <Typography variant="h1" color="initial">
        Ups, contenido no encontrado
        </Typography>
        <Typography variant="p" color="initial">
          El contenido solicitado no se encuentra en la base de datos.<br />
          por favor verifica la URL. O si consideras que esto puede ser un error, me puedes contactar.
        </Typography>
    </>
  );
};

export default Error404;
